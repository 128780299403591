$bv-dropdown-defined: false !default;

// Make sure to include these style definitions only once
@if $bv-dropdown-defined == false {
  $bv-dropdown-defined: true;

  // Hide the caret for `no-caret` setting
  // See: https://github.com/bootstrap-vue/bootstrap-vue/issues/1473
  // See: https://github.com/twbs/bootstrap/issues/23724
  .dropdown {
    &:not(.dropleft) {
      .dropdown-toggle {
        &.dropdown-toggle-no-caret::after {
          display: none !important;
        }
      }
    }

    // See: https://github.com/bootstrap-vue/bootstrap-vue/issues/2909
    &.dropleft {
      .dropdown-toggle {
        &.dropdown-toggle-no-caret::before {
          display: none !important;
        }
      }
    }

    // Hide the focus ring introduced by Bootstratp v4.4 reboot
    // on items with tabindex="-1", as we know how users feel
    // about blue rings where they don't expect them.
    .dropdown-menu:focus {
      outline: none;
    }

    // Prevent dropdown background overflow if there's no padding
    // See https://github.com/twbs/bootstrap/pull/27703
    // Added here to address <li> wrapping of items
    @if $dropdown-padding-y == 0 {
      .dropdown-menu {
        > :first-child {
          .dropdown-item,
          .dropdown-form,
          .dropdown-text {
            @include border-top-radius($dropdown-inner-border-radius);
          }
        }

        > :last-child {
          .dropdown-item,
          .dropdown-form,
          .dropdown-text {
            @include border-bottom-radius($dropdown-inner-border-radius);
          }
        }
      }
    }
  }
}
