/*!
 * BootstrapVue Custom CSS (https://bootstrap-vue.org)
 */

// Include Bootstrap functions, variables, and mixins
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

// Import BootstrapVue custom SCSS
@import "../src/index.scss";
