// Special styling for some BootstrapVue custom form controls that do
// not have a native HTML input type root element (or tabindex)
// Used by BFormSpinbutton, BFormDatepicker, BFormTimepicker, BTime, BCalendar
.form-control {
  // Adds focus styling to the form-control class (via the focus class)
  // Specifically when we are using non focusable elements, or when true focus
  // is within the `.form-control` element.
  // Mimics the `.form-control:focus` styling
  &.focus {
    color: $input-focus-color;
    background-color: $input-focus-bg;
    border-color: $input-focus-border-color;
    outline: 0;
    @if $enable-shadows {
      box-shadow: $input-box-shadow, $input-focus-box-shadow;
    } @else {
      box-shadow: $input-focus-box-shadow;
    }

    &.is-valid {
      border-color: $form-feedback-valid-color;
      box-shadow: 0 0 0 $input-focus-width rgba($form-feedback-valid-color, 0.25);
    }

    &.is-invalid {
      border-color: $form-feedback-invalid-color;
      box-shadow: 0 0 0 $input-focus-width rgba($form-feedback-invalid-color, 0.25);
    }
  }
}
