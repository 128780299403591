$bv-dropdown-form-defined: false !default;

@if $bv-dropdown-form-defined == false {
  // This test will only include these style definitions once
  $bv-dropdown-form-defined: true;

  // Custom styles for <b-dropdown-form>
  // Based on class `.dropdown-item`
  .b-dropdown-form {
    display: inline-block;
    padding: $dropdown-item-padding-y $dropdown-item-padding-x;
    width: 100%;
    clear: both;
    font-weight: $font-weight-normal;

    &:focus {
      // From https://github.com/twbs/bootstrap/blob/master/scss/_reboot.scss
      // mimicking button:focus styling.
      // We add important here as anything with tabindex `-1` and focused will not
      // have a focus ring due to reboot.scss and its `!important` override.
      // Needed for keyboard navigation high-lighting
      outline: 1px dotted !important;
      outline: 5px auto -webkit-focus-ring-color !important;
    }

    &.disabled,
    &:disabled {
      outline: 0 !important;
      color: $dropdown-link-disabled-color;
      pointer-events: none;
      // background-color: transparent;
      // Remove CSS gradients if they're enabled
      // @if $enable-gradients {
      //   background-image: none;
      // }
    }
  }
}
